.main {
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
}

.nav {
    background-color: #3f5070;

    width: 100%;
    font-size: 17px;
}

.nav img {
    width: 30px;
}

.nav .info {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    height: 100%;
}

.nav .info span {
    display: flex;
    align-items: center;
    height: 100%;
    color: white;
}

.nav .info span a {
    color: white;
    text-decoration-line: none;
}
.nav .info span a:hover {
   color: #df7b9a;
}

.nav .info .upper {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav .info .bottom {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.statsBlock {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #CCE3EF;
}

.statsBlock .left {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 28%;
}

.statsBlock .right {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 32%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.statsBlock .center {
    padding-top: 60px;
    padding-bottom: 60px;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.statsBlock .center img {
    width: 100%;
}

.statText {
    text-align: left;
    margin: 0 auto;
    width: 80%;
    font-size: 18px;
    line-height: 1.5;
    color: #3F5070;
}

.statMini {
    display: flex;
    text-align: right;
    margin: 0 auto;
    width: 80%;
    font-size: 12px;
    line-height: 1.5;
    color: #3F5070;
    column-gap: 10px;
}

.statMini .Img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 130px;
}

.statMini .Img img {
    max-width: 100%;
    max-height: 50px;
}

.newsBlock {
    width: 100%;
    display: flex;
    flex-basis: 30%;
    column-gap: 5%;
    padding-bottom: 30px;
}

.name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;
}


.name h3 {
    font-size: 30px;
    font-weight: bold;
    color: #3F5070;
    text-align: left;
    line-height: 1.5;
}

.name a {
    border: 1px solid #3F5070;
    color: #3F5070;
    text-decoration-line: none;
    font-size: 16px;
    padding: 10px 40px;
    border-radius: 7px;
}

.newsCard {
    display: flex;
    flex-direction: row;
    height: 100px;
    column-gap: 20px;
    padding-bottom: 30px;
}

.newsCard .image {
    height: 100%;
    width: 70px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.newsCard .image img {
    width: 70px;
    height: 70px;
}

.newsCard .text {
    row-gap: 10px;
    height: 100px;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.newsCard .text .date {
    color: #7E7E7E;
    font-size: 14px;
}

.newsCard .text .title a {
    font-weight: bold;
    color: #3F5070;
    font-size: 16px;
    text-decoration-line: none;
}
.newsCard .text .title a:hover {

    text-decoration-line: underline;
}

.peopleBlock {
    width: 100%;
    display: flex;
    flex-basis: 22%;

    column-gap: 4%;
    padding-bottom: 60px;
}

.peopleCard {
    position: relative;
    width: 100%;
    height: 300px;
}

.peopleCard img {
    max-width: 100%;
    height: 100%;
}

.peopleCard .text {
    text-align: left;
    color: white;
    position: absolute;
    bottom: 40px;
    left: 20px;
    max-width: 80%;

}

.peopleCard .text a {
    color: white;
    text-decoration-line: none;
    height: 200px;
}
.map {
    width: 100%;
    height: 700px;

    background: url("../../assets/NewSuvu/Russia.png") no-repeat ;
    background-position: left 50% top 70%;
    background-size: contain;
}

.map a {
    text-decoration-line: none;
}

.map a:hover {
    text-decoration-line: underline;
}
.newMap a {
    text-decoration-line: none;
}

.newMap a:hover {
    text-decoration-line: underline;
}

.partners {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: 1fr;
    padding-bottom: 60px;
}

.partners .partner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.partners .partner img {
    padding: 30px 10px;
    max-width: calc(100% - 20px);
    opacity: 0.6;
}
.partners .partner img:hover {
    opacity: 1;
}

.menu {
    cursor: pointer;
    z-index: 200;
}