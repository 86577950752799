
.page {
    max-width: 1200px;
    width: 90%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    margin: 0 auto;
    font-family: 'Proxima Nova Lt';

}
@media (max-width: 1200px) {
    .page {

        grid-template-columns: 1fr;


    }

}
.SUVU {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

.SUVU h2 {
    padding-bottom: 20px;
    border-bottom: 3px solid transparent;
    border-image: linear-gradient(90deg, rgba(99, 16, 36, 1) 10%, rgba(177, 34, 140, 1) 90%);
    border-image-slice: 1;

}

.logsSuvu {
    margin-top: 20px;
}

.logsSuvu img {
    opacity: 0.6;
    max-height: 140px;
}

.logsSuvu img:hover {
    opacity: 1;
    cursor: pointer;
}

.page p {
    margin-top: 40px;
}

.hrefs {
    background-color: rgba(223, 122, 160, 0.1);
    padding: 8px;
    min-height: 200px;
    border-radius: 10px;
    color: #3a4663;
    font-size: 18px;
    font-family: "Proxima Nova Rg";
    font-weight: 500;
    margin-top: 15px;
}

.hrefs a {
    color: #3a4663;
    text-decoration: none;
    font-size: 18px;
    font-family: "Proxima Nova Rg";
}

.hrefs ul {
    margin-left: -20px;
}

.hrefs li {
    list-style: none;
    padding: 5px;
    margin: 0;
    width: 95%;
    min-height: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    text-align: start;
}

.hrefs img {
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;

}

.hrefs li a {
    text-decoration: none;
    color: #3a4663;
}

.hrefs li:hover {
    background-color: rgba(223, 122, 160, 1);
    border-radius: 10px;
    color: white
}

.hrefs li:hover a {
    text-decoration: none;
    color: white
}

.content {
    max-width: 750px;
    width: 100%;
    margin: 0 auto;
    font-size: 17px;
    color: #212529;
    text-align: start;
}

.content img {
    width: 750px;
    max-width: 100%;
}

.pinkbutton {
    background-color: rgba(223, 122, 160, 0.6);
    color: white;
    border-radius: 8px;
    white-space: pre-line;
    min-height: 40px;
    padding: 12px 50px 12px 15px;
    font-size: 20px;
    font-family: "Proxima Nova Rg";
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
}

.pinkbutton a {
    text-decoration: none;
    color: white;
}

.pinkbutton span {
    position: relative;
    right: -50px;
    margin-top: 10px;
}

.pinkbutton:hover {
    color: white;
    background-color: #3a4663;
}

.whitebutton {
    background-color: white;
    color: #3a4663;
    border-radius: 8px;
    white-space: pre-line;
    min-height: 30px;
    padding: 5px 15px;
    padding-top: 10px;
    font-size: 17px;
    font-weight: 500;
    font-family: "Proxima Nova Rg";
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    text-decoration: none;
}

.whitebutton a {
    text-decoration: none;
    color: #3a4663;
}

.whitebutton span {
    position: relative;
    right: -30px;
}

.whitebutton:hover {
    color: white;
    background-color: #3a4663;
}

.whitebutton:hover a {
    color: white;
}

.btnMeshdSotr {
    border: #3a4663 solid 1px;
    background-color: #3a4663;
    font-family: "Proxima Nova Rg";
    border-radius: 10px;
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 150px;
}

.btnMeshdSotr a {
    font-family: "Proxima Nova Rg";
    text-decoration: none;
    color: white;
}

.btnMeshdSotr:hover a {
    font-family: "Proxima Nova Rg";
    text-decoration: none;
    color: #3a4663;
}

.btnMeshdSotr:hover {
    font-family: "Proxima Nova Rg";
    border: #3a4663 solid 1px;
    background-color: white;
    transition: all 0.5s;
}

.LiMeshSotr li {
    padding: 5px 0px 5px 15px;
}

.arrow:hover {
    padding: 3px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}

.concept a {
    text-decoration: none;
    color: #3a4663;
    font-weight: 500;
    font-size: 17.5px;
}

.form {
    height: 80vh;
    max-height: 1200px;
    width: 40vw;
    max-width: 1000px;
    padding: 30px;
}
.form label{
    font-size: 30px;
}

.form h1 {
    font-size: 34px;
    text-align: center;
    color: #3a4663;
    font-family: "Proxima Nova Lt";

}

.form input, select, option, textarea {
    border: 1px solid #a1acd0;
    height: 50px;
    width: 90%;
    border-radius: 10px;
}

.form label {
    font-size: 13px;
    font-family: "Segoe UI";
    color: #3a4663;
}

.blackbutton {
    background-color: #404e6e;
    color: #ffffff;
    border-radius: 8px;
    white-space: pre-line;
    min-height: 30px;
    padding: 5px 15px;
    padding-top: 10px;
    font-size: 17px;
    font-weight: 500;
    font-family: "Proxima Nova Rg";
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    text-decoration: none;
}

.blackbutton a {
    text-decoration: none;
    color: white;
    font-size: 19px;
}

.blackbutton span {
    position: relative;
    right: -30px;
}

.blackbutton:hover {
    color: white;
    background-color: #3a4663;
}

.blackbutton:hover a {
    color: white;
}

.hotlineHref a {
    text-decoration-line: none;
    color: #212529;
}

.hotlineHref a:hover {
    text-decoration-line: underline;
    color: red;
}

.checkBox {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkBox a {
    color: #3A4663;
}

.checkBox a:hover {
    color: #3A4663;
}

.send button {
    border: 1px solid black;
    width: 120px;
    height: 40px;
    border-radius: 10px;
    
}

.concepcA a {
    font-weight: bold;
    text-decoration-line: none;
    color: #212529;
}

.concepcA {
    font-weight: bold;
    text-decoration-line: none;
    color: #212529;
}

.posobie {
    font-size: 17px;
    color: #212529;
    text-decoration-line: none;
}

.posobie a {
    font-size: 17px;
    color: #212529;
    text-decoration-line: none;
}

.posobie:hover {
    font-size: 17px;
    text-decoration-line: underline;
    color: red;
}

.posobie a:hover {
    font-size: 17px;
    text-decoration-line: underline;
    color: red;
}

.concept table {
    border: 1px solid grey;
    border-collapse: collapse;
}

/* границы ячеек первого ряда таблицы */
.concept th {
    border: 1px solid grey;
}

/* границы ячеек тела таблицы */
.concept td {
    border: 1px solid grey;
    padding-left: 10px;
}

.header{
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    align-items: flex-start;
    max-width: 1200px;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}
.newHeader {
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
}