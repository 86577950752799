.minPros {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    background-color: #3B4966;
    text-align: center;
}


.minPros a {
    display: block;
    text-decoration-line: none;
    font-size: 18px;
    color: white;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0 auto;
    width: 90%;
}

.ppms {
    display: flex;
    grid-area: one;
    align-items: center;
    text-align: left;
    justify-content: left;
}

.ppms a img {
    cursor: pointer;
}

.bigMenu ul li {
    padding: 3px;
    list-style-type: none;
    font-size: 14px;
}

.subMenuList {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 20px 25px 10px;
    background: white;
    height: 480px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    width: calc(100% - 50px);
    max-width: 1120px;
    position: relative;
    z-index: 8;
}

.subMenuList a {
    color: #054982;
    text-decoration-line: none;
}

.subMenuList a:hover {
    text-decoration-line: underline;
}

.subMenuList li {
    list-style: none;
    font-size: 17px;
    margin-bottom: 12px;
    margin-right: 12px;
}

.subMenuList .title {
    font-size: 24px;
    margin-bottom: 18px;
    white-space: normal;
    overflow-wrap: normal;
}


.mobileHeader {
    margin: 0 auto;
    position: relative;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    column-gap: 30px;
    max-width: 1200px;

}

.mobileHeader .menu {
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: right;
    color: #CB2D5F;
    width: 10%;
}

.mobileHeader {
    margin-left: 5%;
    margin-top: 10px;
    width: 90%;

    z-index: 2;
}

.mobileHeader .ppms img {
    max-width: 75%;
}

@media screen and (max-width: 1250px) {
    .subMenuList li {
        font-size: 15px;
    }

    .subMenuList .title {
        font-size: 22px;
    }

    .navBar .btn a {
        font-size: 16px;
    }

    .navBar li {
        max-width: 50px;
    }
}

@media screen and (max-width: 1150px) {
    .subMenuList li {
        font-size: 14px;
    }

    .subMenuList .title {
        font-size: 20px;

    }
}

@media screen and (max-width: 1050px) {
    .subMenuList li {
        font-size: 13px;
    }

    .subMenuList .title {
        font-size: 18px;

    }
}

@media screen and (max-width: 999px) {
    .subMenuList {
        height: min-content;
        width: calc(90% - 50px);
        margin: 0 auto;
    }

    .subMenuList li {
        font-size: 17px;
    }

    .subMenuList .title {
        font-size: 24px;

    }
}