    .modals {
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.25);
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        transition: 0.7s;
    }

    .modals__content {
        background-color: #ffffff;
        height: 70vh;
        transition: 0.4s all;
        padding: 1px;
        border-radius: 20px;
        width: 100%;
        max-width: 900px;
        overflow: scroll;
    }


.modals.active {
    opacity: 1;
    pointer-events: all;
    z-index: 120;
}


.modal__content.active{
    transform: scale(1);
}