.fileleftlineRed {
    width: calc(64vw - 20px);
    max-width: 1200px;
    background-color: rgba(69, 84, 118, 0.03);
    min-height: 60px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    border-bottom: 1px solid #dadfe9;
    border-bottom-left-radius: 2px;
    text-align: start;
    margin-right: 20px;
    border-left: rgba(69, 84, 118, 0.03) 4px solid;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    padding-right: 20px;
}

.fileleftlineRed:hover {
    border-left: #ca2357 4px solid;
}

.fileleftlineRed a {
    text-decoration: none;
    color: #3a4663;
    padding: 0px;
    margin: 10px;
    text-align: start;
    align-items: flex-start;
}

.fileleftlineRed img {
    height: 35px;
    max-width: 30px;
    margin-top: 0px;
    padding-left: 20px;

}

.fileleftlineRed a:hover {
    color: #ca2357
}

.fileleftlineRedL {
    max-width: 900px;
    background-color: rgba(69, 84, 118, 0.03);
    min-height: 60px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    border-bottom: 1px solid #dadfe9;
    border-bottom-left-radius: 2px;
    text-align: center;
    margin-right: 20px;
    border-left: rgba(69, 84, 118, 0.03) 4px solid;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding-bottom: 0px;
    padding-top: 15px;
}

.fileleftlineRedL:hover {
    border-left: #ca2357 4px solid;
}

.fileleftlineRedL a {
    text-decoration: none;
    color: #3a4663;
    padding: 0px;
    margin: 0px;
    text-align: start;
    align-items: flex-start;
}

.fileleftlineRedL img {
    max-height: 200px;
    max-width: 150px;
    margin-top: 0px;
    padding-left: 20px;

}

.fileleftlineRedL a:hover {
    color: #ca2357
}

.filesLeftPage {
    /*padding-top: 50px;*/
    padding-bottom: 50px;
    margin-top: 80px;
    margin-bottom: 80px;

    display: grid;
    grid-template-columns: 2fr 1fr;

    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

.filesLeftPage a {
    text-decoration: none;
}

.fileleftlineBlue {
    max-width: 700px;
    background-color: rgba(7, 74, 128, 0.03);
    min-height: 60px;
    font-size: 22px;
    font-family: "Proxima Nova Lt";
    border-bottom: 1px solid #dadfe9;
    border-bottom-left-radius: 2px;
    text-align: start;
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}

.fileleftlineBlue:hover {
    background-color: rgba(7, 74, 128, 0.06);
}

.fileleftlineBlue a {
    text-decoration: none;
    color: #3a4663;
    margin: 20px;
    text-align: start;
    align-items: flex-start;
}

.fileleftlineBlue img {
    height: 35px;
    max-width: 40px;
    margin-top: 10px;
    padding-left: 10px;
}

@media only screen and (max-width: 400px) {
    .filesLeftPage {
        padding-top: 50px;
        padding-bottom: 50px;
        margin-top: 80px;
        margin-bottom: 80px;
        margin-left: 150px;
        display: flex;
        flex-direction: column;
        max-width: 1200px;
        margin-right: 150px;
        margin: 0 auto;
    }
}

.whitebutton {
    background-color: white;
    color: #3a4663;
    border-radius: 8px;
    white-space: pre-line;
    min-height: 30px;
    padding: 5px 15px;
    padding-top: 10px;
    font-size: 17px;
    font-weight: 500;
    font-family: "Proxima Nova Rg";
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    text-decoration: none;
}

.whitebutton a {
    text-decoration: none;
    color: #3a4663;
}

.whitebutton span {
    position: relative;
    right: -30px;
}

.whitebutton:hover {
    color: white;
    background-color: #3a4663;
}

.whitebutton:hover a {
    color: white;
}

.kdnContacts {
    text-align: left;
    color: #212529;
    font-family: "Segoe UI";
    font-weight: 100;
}

.kdnContacts a {
    text-decoration: none;
    color: #005090;
    font-family: "Segoe UI";
}

.kdnContacts a:hover {
    text-decoration: none;
    color: #005090;
    font-family: "Segoe UI";
}

.improvement a {
    color: #212529;
    text-decoration-line: underline;
}

.improvement a:hover {
    color: red;
}

.pinkbutton {
    background-color: rgba(223, 122, 160, 0.6);
    color: white;
    border-radius: 8px;
    white-space: pre-line;
    min-height: 40px;
    padding: 12px 50px 12px 15px;
    font-size: 20px;
    font-family: "Proxima Nova Rg";
    margin-top: 20px;
    display: grid;
    grid-template-columns: 4fr 1fr;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
}

.pinkbutton a {
    text-decoration: none;
    color: white;
}

.pinkbutton span {
    position: relative;
    right: -50px;
    margin-top: 10px;
}

.pinkbutton:hover {
    color: white;
    background-color: #3a4663;
}

.header {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.dopHeader {
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.KDNfileleftlineRed {
    max-width: 750px;
    width: 90%;
    background-color: rgba(69, 84, 118, 0.03);
    min-height: 60px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    border-bottom: 1px solid #dadfe9;
    border-bottom-left-radius: 2px;
    text-align: start;
    margin-right: 20px;
    border-left: rgba(69, 84, 118, 0.03) 4px solid;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.KDNfileleftlineRed:hover {
    border-left: #ca2357 4px solid;
}

.KDNfileleftlineRed a {
    text-decoration: none;
    color: #3a4663;
    padding: 0px;
    margin: 10px;
    text-align: start;
    align-items: flex-start;
}

.KDNfileleftlineRed img {
    height: 35px;
    max-width: 30px;
    margin-top: 0px;
    padding-left: 20px;

}

.KDNfileleftlineRed a:hover {
    color: #ca2357
}

.KDNfileleftlineRedNew {
    width: 750px;
    max-width: 1200px;
    background-color: rgba(89, 84, 148, 0.13);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    min-height: 60px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    border-bottom: 1px solid #dadfe9;
    border-bottom-left-radius: 2px;
    text-align: start;
    margin-right: 20px;
    border-left: #ca2357 4px solid;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.KDNfileleftlineRedNew a {
    text-decoration: none;
    color: #3a4663;
    padding: 0px;
    margin: 10px;
    text-align: start;
    align-items: flex-start;
}

.KDNfileleftlineRedNew img {
    height: 35px;
    max-width: 30px;
    margin-top: 0px;
    padding-left: 20px;

}

.KDNfileleftlineRedNew a:hover {
    color: #ca2357
}


.answer {
    margin-top: -2px;
    margin-right: 20px;
    width: 710px;
    background-color: rgba(89, 84, 148, 0.13);
    min-height: 60px;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
    padding: 20px;
    border-bottom: 1px solid #dadfe9;
    text-align: start;
    border-left: #ca2357 4px solid;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    line-height: 1.5;
    margin-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}

.hrefs {
    background-color: rgba(223, 122, 160, 0.1);
    padding: 8px;
    min-height: 200px;
    border-radius: 10px;
    color: #3a4663;
    font-size: 18px;
    font-family: "Proxima Nova Rg";
    font-weight: 500;
    margin-top: 75px;
}

.hrefs a {
    color: #3a4663;
    text-decoration: none;
    font-size: 18px;
    font-family: "Proxima Nova Rg";
}

.hrefs ul {
    margin-left: -20px;
}

.hrefs li {
    list-style: none;
    padding: 5px;
    margin: 0;
    width: 95%;
    min-height: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    text-align: start;
}

.hrefs img {
    height: 25px;
    margin-left: 3px;
    margin-right: 3px;

}

.hrefs li a {
    text-decoration: none;
    color: #3a4663;
}

.hrefs li:hover {
    background-color: rgba(223, 122, 160, 1);
    border-radius: 10px;
    color: white
}

.hrefs li:hover a {
    text-decoration: none;
    color: white
}

.filesLeftPageKDN {
    /*padding-top: 50px;*/
    padding-bottom: 50px;
    margin-top: 80px;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 1200px;
    margin: 0 auto;
}

.filesLeftPageKDN a {
    text-decoration: none;
}

.filesLeftPageKDNN {
    /*padding-top: 50px;*/
    padding-bottom: 50px;
    margin-top: 80px;
    margin-bottom: 80px;
    display: grid;
    grid-template-columns: 2fr 0.1fr 0.9fr;
    max-width: 1200px;
    margin: 0 auto;
}

.filesLeftPageKDNN a {
    text-decoration: none;
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    align-items: flex-start;
    max-width: 1200px;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

.newHeader .part {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.newHeader .part a {
    font-weight: normal;
    color: #3a4663;
    text-align: left;
    max-width: 1200px;
    margin: 10px auto;
    font-family: Proxima Nova Lt;
    font-size: 20px;
    text-align: start;
    width: 100%;
}

.newHeader .part a:hover {
    color: red;
    text-decoration-line: underline;
}

.terminsText {
    font-size: 20px;
    line-height: 1.5;
    font-family: "Proxima Nova Lt";
    text-align: justify;
    max-width: 750px;
}

.questTable table {
    width: 100%;
    max-width: 750px;
    border-collapse: collapse;
    border: 1px solid #3a4663;
    text-align: left;
    line-height: 1.5;
    font-size: 20px;
    font-family: "Proxima Nova Lt";
}

.questTable table th {
    border: 1px solid #3a4663;
    padding: 8px;
}

.questTable table td {
    border: 1px solid #3a4663;
    padding: 8px;
    vertical-align: top;
}

.questTable th:first-child {
    width: 30%;
}

.questTable td:first-child {
    width: 30%;
}

@media (max-width: 1200px) {
    .filesLeftPage {
        grid-template-columns: 1fr;

    }
    .KDNfileleftlineRed {
        width: 100%;
        max-width: 1200px;
    }
}
