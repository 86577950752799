.foot {
    max-width: 1300px;
    margin: 0 auto;
    position: static;
    bottom: 0;
}

.foot a {
    text-decoration: none;
    color: #fff;
    opacity: .8;
    font-size: 18px;
}

.foot a:hover {
    text-decoration: underline;
}

.foot li {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: start;
}

.foot li img {
    height: 20px;
    width: 20px;
    padding-right: 3px;
}

.foot {
    font-family: 'Proxima Nova Rg';
}

.fill {
    fill: #fff;
}

.footblocks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    color: #fff;
}

@media (max-width: 1200px) {
    .footblocks {
        grid-template-columns: 1fr;
    }
}

.footPad {
    padding: 0 100px 0 100px;
    background-color: #455476;
}

.allmer {
    opacity: 0.6;
    margin: auto;
    padding: 15px;
    border: solid 1px white;
    width: 170px;
    border-radius: 8px;
}

.allmer:hover {
    background-color: #DF7B9A;
    color: #fff;
    opacity: 1;
}

.ALi a {
    text-decoration: none;
}

.ALi li {
    list-style: disc;
    padding: 1px;
    margin: 5px;
    text-align: start;
    opacity: 1;
    font-size: 20px;

}

.ALi li:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
}

.ulBlock {
    margin: 10px 10px 20px 10px;
    opacity: 0.6;
    font-size: 20px;
    text-underline-offset: 10px;
    cursor: pointer
}

.ulBlock li {
    opacity: 1;
}

.ulBlock a {
    text-decoration: none !important;

}

.ulBlock li:hover a {
    text-decoration: underline;
}

.navigation a {
    font-size: 16px;
    margin-top: 5px;
}

.navigation ul {
   padding: 0;
}

.navigation a:hover {
    color: #FFDAA8
}

.navigation li img {
    padding-right: 5px;
}

.bottom {
    display: flex;
    justify-content: space-between
}
@media (max-width: 1200px) {
    .bottom {
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 20px;
    }
}

@media only screen and (max-width: 400px) {
    .footblocks {
        grid-template-columns: 1fr;
    }

    .footPad {
        padding: 10px 20px 0px 10px;
    }

    .navigation ul {
        padding: 0px;
    }
}

@font-face {
    font-family: "Proxima Nova Lt";
    src: url("../../assets/fonts/ProximaNova/proximalight.ttf");
}

@font-face {
    font-family: "Proxima Nova Rg";
    src: url("../../assets/fonts/ProximaNova/proximanova_regular.ttf");
}

@font-face {
    font-family: "Proxima Nova bold";
    src: url("../../assets/fonts/ProximaNova/proximanova_boldit.otf");
}

@font-face {
    font-family: "Segoe UI";
    src: url("../../assets/fonts/Segoe\ UI\ Bold\ Italic.ttf");
}
 