.open {
    font-weight: normal;
    padding-top: 50px;
    color: #212529;
    text-align: left;
    max-width: 1200px;
    width: 90%;
    font-family: Proxima Nova Lt;
    font-size: 19px;
    margin: 0 auto;
}

.open a:hover {
    text-decoration: underline;
    color: red;
}
.open a {
    text-decoration: none;
    text-decoration-line: none;
    color: #3A4663;
    font-family: "Proxima Nova Rg";
}
.header{
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 50px
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    align-items: flex-start;
    max-width: 1200px;
    width: 90%;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

@media screen and (max-width: 1200px){
    .grid {
        display: grid;
        grid-template-columns: 1fr;
        margin-bottom: 50px;
    }
}