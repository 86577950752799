  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .fall-container {
    position: relative;
    left: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .fall-trigger {
    background: #ffffff;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto; /* Strictly for positioning */
  }
  
  .fall-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
  
  .fall-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
  }
  
  .fall-trigger img {
    border-radius: 90px;
  }
  
  .fall {
    background: #ffffff;
    border-radius: 15px;
    position: absolute;
    width: calc((100vw - 300px) / 3 );
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0.1;
    border:#df7bda 1px solid;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    margin-top: 20px;
  }
  
  .fall.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .fall ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .fall li {
    border-bottom: 1px solid #dddddd;
    border-radius: 5px;
  }
  .fall li:hover{
    background-color: #d386cf;
    color: white;
  }
  .fall li a:hover {
    color: white;
  }
  .fall li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
  }
  