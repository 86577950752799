.info {
    color: brown;
    width: 60vw;
    opacity: 0;
    visibility: hidden;
    height: 0px;
}

.info ul {
    height: 0;
}

.arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    cursor: pointer;
    background-color: #455476;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    color: white;
    opacity: 1;
    z-index: 4;
    top: -50%;
}

.downarrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 30px;
    cursor: pointer;
    background-color: #500d50;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    color: white;
    opacity: 1;
    z-index: 4;
    top: -50%;
    rotate: 180deg;
}

.arrow:hover {
    background-color: brown;
    transition: all 2s;
}

.arrow2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    cursor: pointer;
    background-color: #455476;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    color: white;
    opacity: 1;
    z-index: 4;
    top: -50%;
}

.downarrow2 {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    cursor: pointer;
    background-color: #500d50;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    color: white;
    opacity: 1;
    z-index: 4;
    top: -50%;
    rotate: 180deg;
}

.arrow2:hover {
    background-color: brown;
    transition: all 2s;
}


.ico {
    z-index: 5;
    background-size: cover;
    border-radius: 10px;
    position: absolute;
    right: 100px;
}

.icoDop {
    z-index: 5;
    width: 600px;
    background-repeat: no-repeat;
    border-radius: 10px;
    background-position: right;
}

.icoMediacia {
    z-index: 5;
    width: 600px;
    background-size: cover;
    border-radius: 10px;
}

.fall {
    max-width: 1200px;
    cursor: pointer;
    margin-top: 30px;
    text-align: start;
    width: 100%;
    position: relative;
}

.fallact {
    width: 60vw;
    background: rgba(253, 235, 242, .8);
    margin: 0 auto;
    max-width: 1200px;
    cursor: pointer;
    border-radius: 10px;
}

.block {
    display: flex;
    flex-direction: row;
    background: rgba(253, 235, 242, .8);
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    justify-content: space-between;
    align-items: center;
}

.blockHead {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 40px;
    font-size: 24px;
    line-height: 1.2;
    font-family: 'Proxima Nova Lt';
    color: #3A4663;
    font-weight: 200;
    max-width: 50%;
}

.fall img {
    height: 100%;
}

.fallact img {
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
}

.active {
    opacity: 1;
    visibility: visible;
    background-color: white;
    color: white;
    border: pink solid 2px;
    border-radius: 2px;
    margin-bottom: 30px;
}

.fallact {
    width: 60vw;
    background: rgba(253, 235, 242, .8);
    margin: 0 auto;
    max-width: 1200px;
    cursor: pointer;
}

.active li {
    list-style: none;
    padding: 0;
    margin: 0;
    min-height: 40px;
    margin-left: 0px;
    margin: 5px;
    margin-top: 15px;
    width: 100%;
}

.active ul {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 90%;
    margin: 0 auto;
}

.fall2 {
    width: 90%;
    max-width: 1000px;
    cursor: pointer;
    margin: 0 auto;
    text-align: start;
}

.fall2Med {
    width: 95%;
    max-width: 1200px;
    cursor: pointer;
    margin-top: 30px;
    text-align: start;
    position: relative;
}

.hrefblock {
    min-height: 34px;
    border: 2px solid #3a4663;
    display: inline-block;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 8px;
    color: #3a4663;
    border-radius: 10px;
    font-size: 20px;
    text-decoration: none;
    font-family: "Proxima Nova Lt";
    font-weight: 400;
    width: 70%;
    max-width: 750px;
}

.hrefblock:hover {
    background-color: #3a4663;
    color: white;
}

.hrefblock a {
    text-decoration: none;
}

.hrefblock img {
    margin-top: 5px;
    height: 22px;
    padding-left: 5px;
}

.file {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 15px;
    height: auto;
}

.file a {
    text-decoration: none;
    color: #3a4663;
    font-size: 17px;
    font-family: "Proxima Nova Rg";
}

.file a:hover {
    text-decoration: underline;
    color: #ca2357;
}

.file img {
    margin-top: 5px;
    height: 25px;
    padding-right: 5px;
}

.header img {
    height: 90px;
}

.header {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    max-height: 1200px;
    margin-left: 18vw;
}