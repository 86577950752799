.App {
    text-align: center;
}

.AppB * {
    text-align: center;
    background-color: black !important;
    color: white !important;
}

.AppBY * {
    text-align: center;
    background-color: black !important;
    color: yellow !important;
}

.AppBlue * {
    text-align: center;
    background-color: #9DD1FF !important;
    color: #063462 !important;
}

.body {
    min-height: calc(100vh - 300px);
}

.warning {
    display: block;
    padding: 20px;
    border: 2px solid red;
    color: red;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 20px;
    background: #f7f7f7;
    border-radius: 15px;
    animation: blinker 5s linear infinite;
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@media only screen and (max-width: 360px) {
    header {
        width: 100vw;
    }

    .finder {
        margin-left: 0px;
        margin-right: 0px;
    }
}