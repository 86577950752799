.newsPage {
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
}

.newsPage a {
    text-decoration: none;
    color: #3A4663;
    font-family: "Proxima Nova Lt";
    font-size: 19px;
}

.newsList {
    display: flex;
   justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 5%;
    color: #333333;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Proxima Nova Lt"
}

.newsListPrice {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    color: #333333;
    max-width: 1200px;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
}

.icon img {
    max-width: 350px;
    width: 100%;
    min-width: 200px;
    height: 180px;
}

.one {
    text-align: left;
    padding-bottom: 20px;
    border-bottom: #3A4663 solid 1px;
    width: 30%;
    flex-basis: 30%;
    max-width: 30%;
}

.one img {
    max-width: 300px;
}

.subBtn {
    width: 200px;
    height: 40px;
    border: none;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    font-family: "Proxima Nova Rg";
    font-size: 17px;
    color: #3A4663;
    margin-top: 30px;
    border-radius: 10px;
}

.subBtn:hover {
    background-color: #3A4663;
    color: white;
    border-radius: 10px;
}

.fall2 {
    width: 100%;
    max-width: 1000px;
    cursor: pointer;
    margin-top: 30px;
    text-align: start;
    font-family: "Peoxima Nova Lt";
}

.fall2 input {
    width: 300px;
    height: 35px;
    border-radius: 10px;
    margin-left: 20px;
}

.fall2 button {
    margin-left: 20px;
    background-color: #3A4663;
    color: white;
    padding: 12px;
    border-radius: 8px;
    font-size: 20px;
}

.block {
    display: flex;
    flex-direction: row;
    background: rgba(253, 235, 242, .8);
    max-height: 300px;
}

.blockHead {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 40px;
    font-size: 27px;
    line-height: 1.2;
    font-family: 'Proxima Nova Lt';
    color: #394663;
    font-weight: 200;
}

.ico {
    z-index: 5;
    width: 600px;
    background-size: cover;
}

.btn {
    background-color: white;
    color: #3A4663;
    padding: 20px;
    width: 230px;
    border-radius: 15px;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    text-decoration: none;

}

.btn a {
    text-decoration: none !important;
    color: #3A4663;
}

.btn:hover {
    background-color: #3A4663;
    color: white;
    text-decoration: dotted;
}

.btn img {
    width: 20px !important;
    height: 20px;
    min-width: 15px;
}

.topBlock {
    display: flex;
    justify-content: space-between;

}

.topBlock img {
    height: 24px;
    width: 24px;
    margin-bottom: 0px !important;
}

.filesLeftPage {
    padding-bottom: 120px;
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    max-width: 1200px;
    margin: 0 auto;
}

.header{
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.iconNews img {
    max-width: 400px;
    width: 100%;
    min-width: 160px;
    height: 225px;
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    align-items: flex-start;
    max-width: 1200px;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

@media screen and (max-width: 1200px){
    .one {
        width: 45%;
        flex-basis: 45%;
        max-width: 45%;
    }
    .filesLeftPage {

        grid-template-columns: 1fr;
    }
    .newsList {
        column-gap: 10%;
    }
    .iconNews img {
        height: 300px;
    }

}
@media screen and (max-width: 768px){
    .one {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
    }
    .newsList {
        column-gap: 0;
    }


    .one img {
        max-width: 100%;
    }
    .iconNews img {
        height: auto;
    }
}