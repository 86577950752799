.hrefblock {
    min-height: 34px;
    border: 2px solid #3a4663;
    display: inline-block;
    padding: 5px;
    max-width: 750px;
    color: #3a4663;
    border-radius: 10px;
    font-size: 20px;
    text-decoration: none;
    font-family: "Proxima Nova Rg";
    font-weight: 550;
}

.hrefblock:hover {
    background-color: #3a4663;
    color: white;
}

.hrefblock a {
    text-decoration: none;
}

.hrefblock img {
    margin-top: 5px;
    height: 25px;
    padding-left: 5px;
}

.file {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: 10px;
}

.file a {
    text-decoration: none;
    color: #3a4663;;
}

.file a:hover {
    text-decoration: underline;
    color: #ca2357;
}

.file img {
    margin-top: 5px;
    height: 25px;
    padding-right: 5px;
}

.header img {
    height: 90px;
    margin-right: 10px;
}

.header {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.blockText {
    font-size: 17px;
    color: #212529;
    font-family: "Proxima Nova Lt";
}

.blockText a {
    text-decoration-line: none;
    color: #212529;
    font-weight: bolder;
}

.blockText a:hover {
    text-decoration-line: underline;
    color: darkred;
    font-weight: bolder;
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    align-items: flex-start;
    max-width: 1200px;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}
.newHeader {
    margin: 0 auto;
    align-items: flex-start;
    max-width: 1200px;
    width: 90%;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

.superHeader img {
    height: 90px;
    margin-right: 10px;
}

.superHeader {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 1200px;
    width: 90%;
    color: #3a4663;
    font-size: 30px;
}

.main {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
    font-size: 20px;
    text-align: justify;
    line-height: 1.5;
    color: #3a4663;
}

.main .logo {
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
}

.main .logo img {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.list li {
    padding-bottom: 6px;
}

.videoBlock {
    border-top: 1px solid #dadfe9;
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 5%;
    padding-bottom: 25px;

}

.videoBlock .left {
    width: 40%;
    font-size: 15px;
}

.videoBlock .right {
    width: 55%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    row-gap: 40px;
}

.videoBlock a {
    text-decoration-line: none;
}

.videoBlock a:hover {
    text-decoration-line: underline;
}

.presentation {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.videoBlock .right a {
    text-decoration-line: none;
    color: #3a4663;
    font-weight: bold;
}

.categories {
    display: flex;
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 5px;
    margin-bottom: 15px;
}

.category {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 12px;
    font-size: 16px;
    color: white;
    opacity: 0.7;
}


.filter {
    width: 100%;
    display: block;
    border-radius: 20px;
    background-color: white;
    height: min-content;
    margin-bottom: 30px;
}

.filter .filterTitle {
    width: 80%;
    margin: 0 auto;
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: bolder;
    color: #212529;
}

.filter .filterRefs {
    display: block;
    height: min-content;
    margin-bottom: 8px;
    margin-top: 8px;
}

.href:last-child {
    margin-top: 8px;
    border-bottom: none;
}

.href:first-child {
    border-bottom: none;
    margin-bottom: 8px;
}

.filter .filterRefs a {
    color: #3a4663;
    text-decoration-line: none;
}

.href {
    width: 100%;
    border-radius: 20px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #cccccc;
}

.href a {
    color: white;
    padding-top: 13px;
    width: calc(8 / 9 * 100%);
    padding-bottom: 13px;
    font-size: 18px;
}

.href a:hover {
    color: #cb2d5f;
}

.mCont {
    display: flex;
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    flex-direction: row;
    column-gap: 30px;
    align-items: center;
    font-size: 28px;
    padding-bottom: 35px;
}

.mCont a {
    color: #3a4663;
    text-decoration-line: none;
    display: flex;
    column-gap: 10px;
    align-items: center;
}

.mCont a img {
    height: 30px;
}
@media  (max-width: 1200px) {
    .superHeader img {
       display: none;
    }
    .superHeader img {
       display: none;
    }
    .mCont {
       flex-direction: column;
        row-gap: 20px;
        align-items: flex-start;
    }
}
@media screen and (max-width: 1000px) {
    .videoBlock {
        flex-direction: column;
    }

    .videoBlock .left {
        width: 100%;
    }

    .videoBlock .right {
        width: 100%;
    }
}