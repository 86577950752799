.info {
    color: brown;
    width: 60vw;
    opacity: 0;
    visibility: hidden;
}

.info ul {
    height: 0;
}

.arrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -170%;
    cursor: pointer;
    background-color: #455476;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    color: white;
    opacity: 1;
    z-index: 4;
    top: -50%;
}

.downarrow {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -170%;
    cursor: pointer;
    background-color: #500d50;
    border-radius: 25px;
    height: 50px;
    width: 50px;
    color: white;
    opacity: 1;
    z-index: 4;
    top: -50%;
    rotate: 180deg;
}

.arrow:hover {
    background-color: brown;
    transition: all 2s;
}


.ico {
    z-index: 5;
    width: 600px;
    background-size: cover;
}

.fall {
    width: 64vw;
    max-width: 1200px;
    margin: 0 auto;
    cursor: pointer;
    margin-top: 30px;
    text-align: start;

}
.fall li img{
    padding-right: 7px;
}

.fallact {
    width: 60vw;
    background: rgba(253, 235, 242, .8);
    margin: 0 auto;
    max-width: 1200px;
    cursor: pointer;
}

.block {
    display: flex;
    flex-direction: row;
    background: rgba(253, 235, 242, .8);

}

.blockHead {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 40px;
    font-size: 24px;
    line-height: 1.2;
    font-family: 'Proxima Nova Lt';
    color: #3A4663;
    font-weight: lighter;
}

.fall img {
    height: 100%;
}

.fallact img {
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
}

.active {
    opacity: 1;
    visibility: visible;
    background-color: white;
    color: white;
    border: pink solid 2px;
    border-radius: 2px;
}

.activeRep {
    opacity: 1;
    visibility: visible;
    background-color: white;
    color: white;
    border: pink solid 2px;
    border-radius: 2px;
}

.activeRep a {
    text-decoration: none;
    color: #3A4663;
    font-family: "Proxima Nova Rg";
    font-size: 17px;
}

.activeRep li:hover a {
    text-decoration: underline;
    color: #ca2357;
}

.fallact {
    width: 60vw;
    background: rgba(253, 235, 242, .8);
    margin: 0 auto;
    max-width: 1200px;
    cursor: pointer;
}

.active li {
    list-style: decimal;
    padding: 0;
    margin: 0;
    min-height: 40px;
    margin-left: 0px;
    margin: 5px;
    color: #3A4663;
    text-align: start;
}

.contacts {
    margin: 0 auto;
    width: 64vw;
    max-width: 1200px;
    font-size: 19px;
    font-family: "Proxima Nova Lt";
    text-align: left;
    color: #3a4663;
}

.contacts ul {

}


.contacts li {
    list-style: none;
    border-bottom: 1px solid #dadfe9;
    padding-bottom: 30px;
    padding-top: 30px;
    padding-left: 10px;
}

.contacts li a {
    text-decoration: none;
    color: #3A4663;
    font-size: 19px;
}

.contacts img {
    height: 40px;
    color: #3a4663;
    width: 40px;
    margin-right: 20px;
}

.activeRep li {
    list-style: disc;
    padding: 0;
    margin: 0;
    min-height: 40px;
    margin-left: 0px;
    margin: 5px;
    color: #3A4663;
    text-align: start;
}

.inner li {
    list-style: none;
}

.active ul {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.activeRep ul {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.fall2 {
    width: 90%;

    max-width: 1000px;
    cursor: pointer;
    margin-top: 30px;
    text-align: start;
}

.hrefblock {
    min-height: 34px;
    border: 2px solid #3a4663;
    display: inline-block;
    padding: 5px;
    max-width: 750px;
    color: #3a4663;
    border-radius: 10px;
    font-size: 20px;
    text-decoration: none;
    font-family: "Proxima Nova Rg";
    font-weight: 550;
}

.hrefblock:hover {
    background-color: #3a4663;
    color: white;
}

.hrefblock a {
    text-decoration: none;
}

.hrefblock img {
    margin-top: 5px;
    height: 25px;
    padding-left: 5px;
}

.file {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.file a {
    text-decoration: none;
    color: #3a4663;;
}

.file a:hover {
    text-decoration: underline;
    color: #ca2357;
}

.file img {
    margin-top: 5px;
    height: 25px;
    padding-right: 5px;
}

.header img {
    height: 90px;
}

.header {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    max-height: 1200px;
    margin-left: 18vw;
}

.pfhd li {
    list-style: none;
}

.fd li {
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    color: #212529;
}
.fd  {
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
}

.vkimg img {
    height: 50px;
    width: 50px;
    border-radius: 50px;
    border: 1px solid #3A4663;
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    align-items: flex-start;
    max-width: 1200px;
    width: 100%;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

.newHeader {
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}