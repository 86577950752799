.news {
    display: grid;
    grid-template-columns: 2fr 1fr;
    padding-top: 40px;
    margin-left: 100px;
    margin-right: 180px;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 40px;
}

.newsBlock {
    padding-left: 10px;
    text-align: start;
    padding: 0px;
    font-weight: 600;
}

.newsBlock span {
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    color: #212529;
    font-weight: 600;
}

.newsBlock a {
    font-family: 'Proxima Nova Lt';
    font-size: 18px;
    font-weight: 500;
    text-indent: 0px
}

.newsBlock:hover h3 {
    text-decoration: underline;
}

.img {
    overflow: hidden;
    height: 480px;
    margin-right: 30px;
    min-width: 300px;
}

.img img {
    min-width: 300px;
    width: 55vw;
    max-width: 970px;
    height: 480px;
    max-width: 750px;
    object-fit: fill;
    overflow: hidden;
    -webkit-transition: all 2s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 2s ease;
    transition: all 2s ease;

}

.img img:hover {
    -webkit-transform: scale(1.2); /* Safari and Chrome */
    -moz-transform: scale(1.2); /* Firefox */
    -ms-transform: scale(1.2); /* IE 9 */
    -o-transform: scale(1.2); /* Opera */
    transform: scale(1.2);
    overflow: hidden;
}

.main {
    background-color: #eaf1f4;
}

.description {
    max-width: 400px;
    height: auto;
}

.description a {
    padding-left: 0px;
    text-decoration: none;
    color: rgba(29, 29, 32, 0.855);
    font-size: 19px;
    font-weight: 500;
}

.calendarBlock {
    max-width: 1200px;
    margin: 0 auto;
    background-color: #fff;
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    padding-top: 20px;
    font-family: "Proxima Nova Lt";
    font-size: 20px;
    color: #333333;
}

.directions {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
}


.seven {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 15px;
}

.four {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}

.seven img {
    height: 80px;
}

.dirBlock {
    background-color: white;
    height: 170px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.dirBlock:hover {
    box-shadow: 1px 26px 61px -35px rgb(0 0 0 / 82%);
    opacity: 1;

}

.colorImg {
    opacity: 0;
    z-index: 2;
    position: relative;
}

.dirBlock:hover .colorImg {
    opacity: 1;
}

.grayImg {
    opacity: 1;
    z-index: 1;
    position: relative;
}

.dirBlock span {
    text-decoration: none;
    color: black;
    padding: 5px;

}

.dirBlock {
    text-decoration: none;
    padding-top: 20px;
}

.fore {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 2fr 1fr;
    grid-gap: 10px;
}

.fore img {
    height: 80px;

}

.banners {
    padding-top: 30px;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.banners img {
    width: 100%;
    max-width: 1200px;
}

.hotlines {
    padding-top: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
}

.ImgCircles {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}

.ImgCircles img {
    height: 50px;
    width: 50px;
    border-radius: 25px;
    margin-left: 10px;
    zoom: 1;
}

.hotlineBlock {

    background-color: white;
    border: 1px solid #999;
    border-radius: 10px;
}

.hotline {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 250px;
}

.hotline img {
    max-width: 120px;
    max-height: 120px;
    padding: 10px;
}

.hotlineText {
    padding: 10px;
    font-size: 20px;
    text-align: left;
    font-size: 20px;
    font-weight: 300;
    font-family: "Segoe UI";
    color: #212529;
}

.buttonHotl {
    display: inline-block;
    cursor: pointer;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    margin-bottom: 30px;
    border-radius: 10px;
}

.buttonHotl a {
    text-decoration: none;
    color: #3A4663;
    font-family: "Proxima Nova Lt";
    font-size: 17px;
}

.buttonHotl:hover {
    background-color: #404e6e;
    color: white;
}

.buttonHotl:hover a {
    color: white;
}

.helpful {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    width: 90%;
    padding-bottom: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

.helpful img {

    max-height: 110px;
    width: auto;
    cursor: pointer;
    opacity: 0.6;
}

.helpful img:hover {
    opacity: 1;
}

.foot a {
    text-decoration: none;
    color: #fff;
    opacity: .8;
}

.calFind {
    font-family: "Proxima Nova Lt";
    text-align: left;
    max-width: 1200px;
    margin: 0 auto;
}

.allmer {
    opacity: 1;
    margin: auto;
    padding: 15px;
    border: solid 1px #3A4663;
    width: 300px;
    border-radius: 8px;
    margin-bottom: 7px;
}

.allmer:hover {
    background-color: #DF7B9A;
    color: #fff;
    opacity: 1;
}

.allmer:hover a {

    color: #fff;
    opacity: 1;
}

.allmer a {
    text-decoration: none;
    color: #3A4663;
}

.telegram {
    opacity: 1;
    margin: auto;
    padding: 15px;
    border: solid 1px #3A4663;
    width: 300px;
    border-radius: 8px;
    margin-bottom: 7px;
    background: url("../../assets/telega.svg");
    background-repeat: no-repeat;
    height: 150px;
    background-position: 210px 80px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    text-align: start;
}

.teleB {
    background-color: #a2cfe6;
    border-radius: 8px;

}

.telHead {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-bottom: 20px;
}

.telButton {
    background-color: #3A86BD;
    color: white;
    width: 132px;
    padding: 10px 30px;
    margin-top: 20px;
    border-radius: 10px;

}

.telButton a {
    color: white;
}

.telegram:hover {
    background-color: #81c0da;
    color: #fff;
    opacity: 1;
}

.telegram:hover a {

    color: #fff;
    opacity: 1;
}

.telegram a {
    text-decoration: none;
    color: white;
}

.calFind input {
    width: 900px;
    height: 45px;
    border-radius: 5px;
    border: 1px solid #3A4663;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 20px;
}
/* Контейнер слайдера */
.slider {
    position: relative;
    width: 90%;
    max-width: 800px;
    height: 45vw; /* Адаптивная высота под соотношение 16:9 для десктопов */
    margin: 0 auto;
    overflow: hidden;
}

/* Обертка для слайдов */
.sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
}

/* Каждый отдельный слайд */
.slide {
    min-width: 100%;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* Стили для изображений */
.slide img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

/* Кнопки переключения слайдов */
.prev, .next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    font-size: 2rem;
    padding: 0.5rem;
    cursor: pointer;
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.prev:hover, .next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

/* Медиазапросы для небольших экранов */
@media (max-width: 768px) {
    .slider {
        height: 56vw; /* Соотношение сторон 16:9 для планшетов и маленьких экранов */
    }

    .prev, .next {
        font-size: 1.5rem; /* Уменьшение размера кнопок на маленьких экранах */
    }
}

@media (max-width: 480px) {
    .slider {
        height: 60vw; /* Немного увеличенная высота для совсем маленьких экранов (мобильные) */
    }

    .prev, .next {
        font-size: 1.2rem; /* Ещё меньше кнопки для мобильных */
    }
}


@media (768px < width <= 1200px) {
    .seven {
        grid-template-columns: repeat(4, 1fr);
    }

    .fore {
        grid-template-columns: repeat(2, 1fr);
    }

    .hotlines {
        grid-template-columns: repeat(1, 1fr)
    }

    .hotlineBlock {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .hotline {
        width: 70%;
        height: auto;
    }

    .helpful {
        grid-template-columns: repeat(3, 1fr);
        row-gap: 40px;
    }

}

@media only screen and (max-width: 1000px) {
    .img img {
        width: 7vw;
    }

    .finder {
        margin-left: 0px;
        margin-right: 0px;
    }

    .banners {
        margin-top: 30px;
        width: auto;
        max-width: 90%;
    }

}

@media (max-width: 768px) {
    .seven {
        grid-template-columns: repeat(2, 1fr);
    }

    .fore {
        grid-template-columns: repeat(1, 1fr);
    }

    .hotlines {
        grid-template-columns: repeat(1, 1fr)
    }

    .hotlineBlock {
        flex-direction: column;
    }

    .hotline {
        width: 100%;
        height: auto;
    }

    .helpful {
        grid-template-columns: repeat(2, 1fr);
        row-gap: 40px;
    }
}

@media only screen and (max-width: 500px) {
    .helpful {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 40px;
    }
    .hotline img {
        max-width: 100px;
        max-height: 100px;
    }
}

@media only screen and (max-width: 400px) {
    .ImgCircles {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .ImgCircles img {
        height: 50px;
        width: 50px;
        border-radius: 25px;
        margin-left: 10px;
        zoom: 1;
    }

    .calendarBlock {
        max-width: 1200px;
        margin: 0 auto;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        font-family: "Proxima Nova Lt";
        font-size: 20px;
        color: #333333;
    }

    .img {
        width: 100%;
    }

    .img img {
        width: 100%;
    }

    .description {
        height: 0px;
        visibility: hidden;
    }

    .news {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        margin-left: 0px;
        margin-right: 0px;
        width: 100vw;
    }

    .img img {
        width: 50px;
    }

    .description {
        visibility: hidden;
        opacity: 0;
        height: 0px;
    }

    .description a {
        visibility: inherit;
    }

    .description h3 {
        margin-right: 30px;

    }


    .banners {
        width: 0;
        visibility: hidden;

        height: 0px;
    }


    .calFind input {
        width: 99vw;
    }

    .newsBlock {
        height: 0px;
    }

    .hotline {
        grid-template-columns: 1fr;
    }
}
