.rows {
    display: grid;
    grid-template-rows: 50px 155px 50px;
    margin: 0 auto;

}

.minpros {
    background-color: #3b4866;

}

.centerHeader {
    max-width: 1200px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    color: white;
    font-size: 12px;
    align-items: center;
    height: 100%;
    margin: 0 auto;
}

.minText {
    max-width: 1200px;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.minText a {
    text-decoration: none;
    color: #fff;
    font-size: 12px;
    font-family: "Proxima Nova Lt";
}

.minText a:hover {
    text-decoration: underline;
}

.minProsBtns {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.minText img {
    max-height: 34px;
    padding-right: 5px;
}

.picture {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.picture img {
    width: 52vw;
    max-width: 800px;
}

.finder {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 2px;
    max-width: 1200px;
    margin: 0 auto;
}

.hotline {
    display: grid;
    grid-template-rows: 40px 40px 40px;
    grid-gap: 5px;
    margin-top: 5px;
    margin-left: 150px;
}

.block {
    display: grid;
    grid-template-columns: 1fr 6fr;
    height: 38px;
    width: 250px;
    border: 2px solid #3a4663;
    border-radius: 10px;
    color: #404e6e;
    font-size: 12px;
    cursor: pointer;
    font-family: "Proxima Nova Lt";
    text-align: start;

}

.block b {
    font-weight: 600;
    padding-bottom: 4px;
    font-size: 11px;
    margin-top: 3px;
}

.block a {
    color: #404e6e;
    font-size: 12px;
    cursor: pointer;
    font-family: "Proxima Nova Rg";
    text-decoration: none;
    font-weight: 100;
}

.blockM b {
    font-weight: 600;
    padding-bottom: 4px;
    font-size: 11px;
    margin-top: 3px;
}

.blockM a {
    color: #404e6e;
    font-size: 12px;
    cursor: pointer;
    font-family: "Proxima Nova Rg";
    text-decoration: none;
    font-weight: 100;
}

.mobileAdd {
    visibility: hidden;
    opacity: 0;
    height: 0;
    width: 0;
}

.about span {
   margin-bottom: 20px;
    width: 75px
}

.block:hover {
    background-color: #404e6e;
    color: white
}

.block:hover a {
    color: white;
    text-decoration: none;
}

.blockM:hover {
    background-color: #404e6e;
    color: white
}

.blockM:hover a {
    color: white;
    text-decoration: none;
}

.newsBlock h3 a {
    margin-right: 10px;
}

.block b {
    display: block;
    font-size: 12.8px;
    font-family: "Proxima Nova Rg";
}

.block img {
    width: 32px;
    padding: 5px;
    height: 30px;
}

.blockM b {
    display: block;
    font-size: 12.8px;
    font-family: "Proxima Nova Rg";
}

.blockM img {
    width: 32px;
    padding: 5px;
    height: 30px;
}

.finder img {
    height: 28px;
    padding-right: 2px;
    padding-top: 10px;
}
.about img {
    padding-top: 0;
}

.findBlock a {
    text-decoration: none;
    color: #1f2433;
    font-size: 18px;
    font-family: "Proxima Nova Rg";
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
}

.findBlock {
    cursor: pointer;
    font-size: 18px;
    margin-left: 25px;
    min-width: 230px;
}

.findBlock:hover a {
    color: #ff5845
}

.findBack {
    background-color: #f7f7f7;
}

.search {
    margin-right: 5px;
    width: 90px;
    padding: 8px;
    border-radius: 5px;
}

.vision {
    padding: 6px;
    font-size: 16px;
    background-color: #df7b9a;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    align-items: center;
}

.back {
    background-color: #ECECEC;
    height: 50px;

}

.body {
    max-width: 1100px;
    padding-top: 10px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: "Segoe UI";
}

.choose span {
    padding: 5px;
    cursor: pointer;
}

.chooseB1 {
    color: white !important;
    background-color: black !important;

}

.abText {
    padding-top: 20px;
}

.chooseB2 {
    color: yellow !important;
    background-color: #1f2433 !important;

}

.chooseB3 {
    color: yellow !important;
}

.chooseB4 {
    color: #063462 !important;
    background-color: #9DD1FF;
}

.default {
    cursor: pointer;
    border-bottom: 1px dotted #000
}

.mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    overflow: scroll;
    height: 70vh;
}

.mobile ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.leftmob {
    text-align: left;
    padding-left: 5px;
    border-right: solid 3px grey;
}

.mobile li:hover {
    background-color: #dF7b9a;
    color: white;
}

.mobile li a:hover {
    color: white;
}

.mobile li a {
    text-decoration: none;
    color: #333333;
    padding: 10px 20px;
    display: block;
}

.hotlineM {
    display: grid;
    grid-template-rows: 45px 45px 45px;
    grid-gap: 5px;
    margin-top: 5px;
    margin-left: 15px;
}

.blockM {
    display: grid;
    grid-template-columns: 1fr 6fr;
    height: 38px;
    width: 200px;
    border: 2px solid #3a4663;
    border-radius: 10px;
    color: #404e6e;
    font-size: 12px;
    cursor: pointer;
    font-family: "Proxima Nova Lt";
    text-align: start;
}

.rightmob {
    text-align: left;
    padding-left: 4px;
}

@media only screen and (max-width: 1000px) {
    .picture {
        margin-left: 0px;
        margin-right: 0px;
    }

    .finder {
        margin-left: 0px;
        margin-right: 0px;
    }

}

@media only screen and (max-width: 400px) {
    .picture {
        margin-left: 0px;
        width: 400px;
        margin-right: 0px;
        flex-direction: row;
    }

    .picture img {
        padding-top: 5px;
        width: 80vw;
        height: 40px;
    }

    .hotline {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .minpros {
        background-color: #3b4866;
        color: white;
        font-size: 12px;
        align-items: start;
        max-width: 370px;
        height: 100%;
    }

    .block {
        display: grid;
        grid-template-columns: 1fr 6fr;
        height: 30px;
        width: 100vw;
        position: relative;
        left: 5px;
        border: 2px solid #404e6e;
        border-radius: 5px;
        color: #404e6e;
        font-size: 12px;
        cursor: pointer;
    }

    .block img {
        height: 26px;
        width: 26px;
        padding: 2px;
    }

    .finder {
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .minpros {
        background-color: #3b4866;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-top: 5px;
        color: white;
    }

    .minProsBtns {
        margin-top: 10px;
        padding-bottom: 6px;
        visibility: hidden;
        opacity: 0;
        height: 0px;
    }

    .newsBlock h3 a {
        margin-right: 30px;
    }

    .hotline {

    }

    .rows {
        display: grid;
        grid-template-rows: 30px 50px 0px;
    }

    .minpros {
        background-color: #3b4866;
        display: flex;
        justify-content: space-around;
        padding-top: 5px;
        color: white;
        font-size: 10px;

    }

    .minText a {
        font-size: 10px;
        text-align: left;
    }

    .minpros img {
        visibility: hidden;
    }

    .mobileAdd {
        visibility: inherit;
        opacity: 1;
        z-index: 2;
        height: 20px;
        width: 20px;
        color: #ff5845;
        position: absolute;
        right: 0;
        margin-right: 30px;
        top: 25px
    }
}

.socIcon {
    fill: white;
    width: 30px;
    height: 30px;
    border: 1px solid;
    border-radius: 9px;

}

.socIcon:hover {
    border-color: #dF7b9a;
}

.socIcon:hover a svg {
    fill: #dF7b9a
}

.socIcon a svg {
    color: white;
    margin-top: 4px;
    margin-right: 1px;
    width: 21px;
    height: 21px;
}

.newAddress {
    background-color: #f7dde5;
    width: 100%;
    min-height: 43px;
    color: #cb2b62;
    font-family: "Proxima Nova Lt";
    font-size: 18px;
}

.newAddress .info {
    font-weight: bold;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: left;
    display: flex;
    align-items: center;
    height: 100%;
}

@keyframes telega {
    0% {
        background-color: transparent;
    }
    100% {
        background-color: #a2cfe6;
    }
}

.telegram {
    opacity: 1;
    margin: auto;
    padding: 15px;
    border: solid 1px #3A4663;
    width: 170px;
    border-radius: 8px;
    background: url("../../assets/telega.svg");
    background-repeat: no-repeat;
    height: 160px;
    background-position: 210px 80px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    text-align: start;
}

.teleB {
    position: relative;
    left: -55%;
    top: 105px;

    border-radius: 8px;
    animation: telega 2s infinite;
}

.telHead {
    font-size: 18px;
    font-weight: bold;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding-bottom: 10px;
}

.telButton {
    background-color: #3A86BD;
    color: white;
    padding: 10px 30px;
    margin-top: 20px;
    border-radius: 10px;
    font-family: "Proxima Nova Lt";
    font-size: 16px;
    font-weight: 600;
}

.telButton a {
    color: white;
}

.telegram:hover {
    background-color: #81c0da;
    color: #fff;
    opacity: 1;
}

.telegram:hover a {

    color: #fff;
    opacity: 1;
}

.telegram a {
    text-decoration: none;
    color: white;
}
