
.hrefblock {
    min-height: 34px;
    border: 2px solid #3a4663;
    display: inline-block;
    padding: 5px;
    max-width: 750px;
    color: #3a4663;
    border-radius: 10px;
    font-size: 20px;
    text-decoration: none;

}

.hrefblock:hover {
    background-color: #3a4663;
    color: white;
}

.hrefblock a {
    text-decoration: none;
}

.hrefblock img {
    margin-top: 5px;
    height: 23px;
    padding-left: 5px;
}

.file {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.file a {
    text-decoration: none;
    color: #3a4663;;
}

.file a:hover {
    text-decoration: underline;
    color: #ca2357;
}

.file img {
    margin-top: 5px;
    height: 25px;
    padding-right: 5px;
}

.header img {
    height: 90px;
}

.header {
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
}

.block {
    display: flex;
    flex-direction: row;
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
    background: rgba(253, 235, 242, .8);
    justify-content: space-between;
    margin-bottom: 30px;
}

.fall {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
    cursor: pointer;
    margin-top: 30px;
}

.ico {
    z-index: 5;
    min-width: 400px;
    max-width: 600px;
    background-size: cover;
}

.blockHead {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    max-width: 800px;
    padding-bottom: 20px;
}

.blockHead img {
    height: 40px;
    margin-left: 20px;
}

.textDetstva {
    margin-top: 20px;
    color: #3a4663;
    font-size: 24px;
    text-align: left;
    padding-left: 20px;
    display: flex;
}

.textDetstva img {
    height: 30px;
    margin-right: 5px;
    color: #ca2357;
}

.text {
    margin-top: 20px;
    color: #3a4663;
    font-size: 24px;
    text-align: left;
    padding-left: 20px;
}

.text img {
    height: 30px;
    margin-right: 5px;
    color: #ca2357;
}

.button {
    padding: 15px;
    width: 120px;
    margin-left: 25px;
    margin-top: 20px;
    border-radius: 9px;
    background-color: #fff;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%)
}

.button a {
    text-decoration: none;
    color: #3a4663;
}

.button:hover a {
    text-decoration: none;
    color: #fff;
}

.button:hover {
    color: #fff;
    background-color: #3a4663;
}

@media screen and (max-width: 768px) {
    .ico {
       display: none;
    }
    .block {
        width: 90%;
        max-width: 90%;
    }

    .text {
        padding-left: 0;
        width: 90%;
        margin: 0 auto;
        padding-top: 30px;
    }

}

@media only screen and (max-width: 400px) {

}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    width: 100%;
    align-items: flex-start;
    max-width: 1200px;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

.newHeader {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}