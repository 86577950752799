.header{
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.page{
    margin: 0 auto;
    max-width: 1000px;
    width: 90%;
    text-align: left;
    line-height: 28px;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    color: #212529;
}
.page a{
    text-decoration: none;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    color: #212529;
    cursor: pointer;
}
.page img {
    max-width: 100%;
}

.noBullet {
    list-style-type: none;
}

.page a:hover{
    text-decoration-line: underline;
    color: red;
    text-decoration-color: red;
}

.page img{
    max-width: 100%;
    margin-bottom: 15px;
}
.pageWRef{
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.mediacia{
    font-size: 16px;
    color: #212529;
    font-family: "Segoe UI";
}
.mediacia span{
    text-decoration: underline;
    color: #3A4663;
    font-size: 17px;
}
.boldec{
    font-size: 17px;
    color: #212329;
    font-family: "Segoe UI";
}
.boldec span{
    color: #3A4663;
    font-weight: 600;
}
.newsrefs{
    margin: 0 auto;
    max-width: 1000px;
    text-align: left;
    line-height: 28px;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    color: #212529;
    display: grid;
    grid-template-columns: 2fr 1fr;
}
.newsrefs img{
    max-width: 750px;
}
.blank{
    margin: 0 auto;
    max-width: 1200px;
    text-align: start;
}
.blank h1{
    margin-top: 30px;
    
}
.webPage{
    margin: 30 auto;
    max-width: 1000px;
}

.mediaciaBase {
    font-family: Proxima Nova Lt;
    font-size: 17px;
    color: #212529;
}

.mediaciaBaseTermins {
    font-family: Proxima Nova Lt;
    font-size: 17px;
    color: #3a4663;
    text-decoration-line: underline;
    font-weight: bolder;
}

.blueButtonMediaciaBase {
    background: #0088cc;
    color: #ffffff;
    width: 600px;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Proxima Nova Rg";
    border: 1px #3A4663;
}

.blueButtonMediaciaBase a{
    background: #0088cc;
    color: #ffffff;
    width: 600px;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Proxima Nova Rg";
}

.blueButtonMediaciaBase a:hover {
    color: white;
    text-decoration-line: none;
}

.greenButtonMediaciaBase {
    background: green;
    color: #ffffff;
    width: 600px;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Proxima Nova Rg";
    border: 1px #3A4663;
}

.greenButtonMediaciaBase a{
    background: green;
    color: #ffffff;
    width: 600px;
    text-align: center;
    font-size: 14px;
    margin: 0 auto;
    height: 40px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Proxima Nova Rg";
}

.greenButtonMediaciaBase a:hover {
    color: white;
    text-decoration-line: none;
}

.greyBlock {
    max-width: 1200px;
    background: #eff1f7;
    color: #163189;
    font-size: 28px;
    margin: 0 auto;
    width: 90%;
}

.greyBlock .item {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    max-width: 1200px;
    width: 90%;
    padding-bottom: 20px;
    margin: 0 auto;
    cursor: pointer;
    text-align: center;
}

.region {
    text-align: center;
    cursor: pointer;
    font-size: 28px;
}

.region:hover {
    text-decoration-line: underline;
}

.greyBlock .item:hover {
    text-decoration-line: underline;
}

.greyBlock img {
    height: 34px;
}

.map {
    border: 1px solid #3A4663;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(10, 5vw);
    grid-gap: 15px;
}

.gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photopage{
    margin: 0 auto;
    max-width: 1200px;
    text-align: left;
    line-height: 28px;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    color: #212529;
    display: grid;
    grid-template-columns: 8fr 1fr;
}

.newsA a {
    font-weight: bold;
    text-decoration-line: none;

}
.eduBack span{
    font-size: 22px;
    font-family: "Proxima Nova Lt";
    max-width:"1200px"; 
    margin:"10px auto"
}

.eduBack img{
    max-width: 400px;
}

.threeImg{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
.threeImg img{
    max-width: 310px;
    width: 100%;
    height: 200px;
}
.eduHead{
    max-width: 1200px;
    display: grid;
    grid-template-columns: 7fr 1fr;
    margin-top: 10px;
    cursor: pointer;
    border-top: gray 1px solid;
    text-align: left;
    font-size: 25px;
}
.edudesc{
    text-align: left;
    font-size: 24px;
    font-family: "Proxima Nova Lt";
}
.docs img{
    max-width: 600px;
}

.lockations{
    max-width: calc(100% - 4px);
    border: 2px solid #eff1f7;
    display: grid;
    grid-template-columns: 2fr 9fr 6fr 16fr 6fr 7fr 7fr;
    width: 90vw;
    margin: 0 auto;
    font-size: 18px;
    background-color: #f8f9fb;
    white-space: normal;
    word-break: break-all;
    border-top: none;
    min-width: 600px;
}
.locations {
    max-width: calc(100% - 4px);
    border: 2px solid #eff1f7;
    display: grid;
    grid-template-columns: 2fr 9fr 6fr 10fr 6fr 6fr 6fr 7fr;
    width: 90vw;
    margin: 0 auto;
    font-size: 18px;
    background-color: #f8f9fb;
    white-space: normal;
    word-break: break-all;
    border-top: none;
    overflow-x: scroll;
}

.lockations:hover {
    border-left: 2px solid green;
}
.lockationsCol a{
    cursor: pointer;
    text-decoration: none;
    color: #163189;
}
.lockationsCol a:hover{
    text-decoration: underline;
    color: #3a4663;
}
.lock{
    visibility: hidden;
    
}
.lockationsCol{
    border-left: 2px solid #eff1f7;
    padding: 8px;
    word-break: keep-all;
    text-wrap: normal;
    text-align: left;
    font-size: 15px;
    line-height: 1.5;
    color: #1d1d1d;
}

.newHeader {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.newHeader h2 {
    margin: 0 auto;
    margin-bottom: 40px;
    margin-top: 40px;
    max-width: 1200px;
    align-items: flex-start;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

.refNews a{
    color: orangered;
    text-decoration-line: none;
}

.refNews a:hover {
    text-decoration-line: underline;
}