.newsPage{
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
}
.textEnt{
    width: 90%;
    margin: 0 auto;
    text-align: left;
    line-height: 1.5;
    color: #212529;
    font-family: "Segoe UI";
    font-size: 16px;
    font-weight: 400;
    max-width: 1200px;
    display: flex;
    align-items: flex-start;
}



.hotlineHref a {
    text-decoration-line: none;
    color: #212529;
}

.hotlineHref a:hover {
    text-decoration-line: underline;
    color: red;
}

.editedHotlineHref a {
    text-decoration-line: none;
    color: blue;
}

.editedHotlineHref a:hover {
    text-decoration-line: underline;
    color: blue;
}

.head{
    max-width: 1100px;
    margin-top: 35px;
    margin-bottom: 25px;
    margin-right: 25px;
    text-align: center;
    font-size: 34px;
    font-family: 'Proxima Nova Lt';
    color: #3A4663;
}

.formCitizen{
    max-width: 1200px;
    margin: 40px auto;
    width: 90%;
}
.formCitizen label{
    font-size: 17px;
    font-family: "Segoe UI";
    margin-top: 30px;
    margin-top: 70px;
    color: #212529;
}
.topnames{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
    max-width: 1200px;
}
.topnames input{
    height: 40px;
    border-radius: 8px;
    width: 90%;
    border: #212529 solid 1px;
    font-size: 20px;
    margin: 0 auto;
}
.downfields{
    text-align: start;
    margin-top: 40px;
}
.downfields label{
    margin-top: 100px;
    font-size: 17px;
    font-family: "Segoe UI";
    color: #212529;
}
.downfields input,.downfields textarea,.downfields select,.downfields select option{
    height: 40px;
    border-radius: 8px;
    width: 100%;
    border: #212529 solid 1px;
    font-size: 20px;
}
.choose option{
        height: 30px;
        font-size: 16px;
        font-family: "Segoe UI";
        color: #3A4663;
        border-radius: 0px;
}
option:hover{
        background-color: #3A4663;
        color: #fff;
}
select option:checked,
select option:hover {
    box-shadow: 0 0 10px 100px #000 inset;
}
.downfields textarea{
    min-height: 200px;
}
.downfields div{
    
}
.subBtn{
    width: 200px;
    height: 40px;
    border: none;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    font-family: "Proxima Nova Rg";
    font-size: 17px;
    color: #3A4663;
    margin-top: 30px;
    border-radius: 10px;
    cursor: pointer;

}
.subBtnD{
    width: 200px;
    height: 40px;
    border: none;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    font-family: "Proxima Nova Rg";
    font-size: 17px;
    color: #3A4663;
    margin-top: 30px;
    border-radius: 10px;

}
.subBtn:hover{
    background-color: #3A4663;
    color: white;
    border-radius: 10px;
}
.subBtnD:hover{
    width: 200px;
    background-color: #f0f0f0;
    height: 40px;
    border: none;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    font-family: "Proxima Nova Rg";
    font-size: 17px;
    color: #3A4663;
    margin-top: 30px;
    border-radius: 10px;
}
.rules{
    color: #212529;
    text-align: start;
    font-size: 17px;
    font-family: "Proxima Nova Lt";
    font-weight: 100;
}
.rules li{
    list-style: decimal;
    padding: 0;
    margin: 0;
    margin-top: 10px;
}
.rules h1{
    font-size: 28px;
}
.structure{
    width: 80%;
}
.blocks{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 15px;
    margin-bottom: 30px;
}
.block{
    background-color: #F9F9F9;
    text-align: start;
    padding: 10px;
    border-radius: 10px;
    font-size: 20px;
    font-family: "Segoe UI";
    color: #212529;
    border-bottom: #E06851 4px solid;
}
.block img{
    margin-left: 20px;
    height: 118px;
    border-radius: 50%;
    width: 119px;
    object-fit: cover;
}
.block li{
    list-style-type: square;
    font-size: 16px ;
    font-family: "Segoe UI";
    font-weight: 300;
    color: #212529;
}
.strName{
    font-size: 23px;
    font-family: "Segoe UI";
    margin-top: 20px;
    margin-bottom: 10px;
}
.strWork{
    font-size: 17px;
    font-family: "Segoe UI";
    color: #42506D;
    font-weight: 600;
}
.strEmail a{
    color: #E06851;
    font-family: "Proxima Nova Lt";
    font-size: 17px;
    text-decoration-line: none;
}
.strEmail{
    font-size: 17px;
    margin-top: 20px;
    margin-bottom: 20px;
}
.active{
    color: #42506D;
    font-size: 17px;
    font-family: "Segoe UI";
    text-decoration: underline;
    cursor: pointer;
    margin-top: 12px;
    margin-bottom: 10px;
}

.metHelp {
    font-family: "Segoe UI";
    max-width: 1200px;
    width: 90%;
    margin: 0 auto;
    font-size: 28px;
    color: #212529;
    margin-top: 20px;
}

.spisok a{
    text-decoration-line: none;
    font-size: 16px;
    font-family: "Segoe UI";
    color: #42506D;
    font-weight: 600;
}

.spisok a:hover {
    text-decoration-line: underline;
    font-size: 16px;
    font-family: "Segoe UI";
    color: #42506D;
    font-weight: 600;
}

.header{
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    align-items: flex-start;
    max-width: 64vw;
    color: #3a4663;
    font-size: 30px;
}

.newHeader h2 {
    margin: 0 auto;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    align-items: flex-start;
    max-width: 1200px;
    color: #3a4663;
    font-size: 30px;
    font-weight: normal;
    font-family: Proxima Nova Lt;
    text-align: start;
}

.newHeader img {
    width: 90%;
    margin: 0 auto;
    max-width: 1200px;
}

@media screen and (max-width: 1200px){
    .blocks{
        grid-template-columns: 1fr 1fr;

    }
    .topnames{
        grid-template-columns: 1fr;
    }
}
@media screen and (max-width: 768px){
    .blocks{
        grid-template-columns: 1fr;

    }
}