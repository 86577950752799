
  
  body {
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .menu-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu-trigger {
    background: #ffffff;
    border-radius: 90px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto; /* Strictly for positioning */
  }
  
  .menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }
  
  .menu-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 10px;
  }
  
  .menu-trigger img {
    border-radius: 90px;
  }
  
  .menu {
    background: #ffffff;
    border-radius: 15px;
    position: absolute;
    width: calc((100vw - 300px) / 6 );
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    border:#dF7b9a 1px solid;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
    margin-top: 20px;
  }
  
  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    text-align: start;
    z-index: 2;
    border-bottom: 1px solid #dddddd;
  }
  .menu li:hover{
    background-color: #dF7b9a;
    color: white;
  }
  .menu li a:hover {
    color: white;
  }
  .menu li a {
    text-decoration: none;
    color: #333333;
    padding: 15px 20px;
    display: block;
  }
  @media only screen and (max-width:400px) {
    .menu {
      width: 50vw;
    }
  }