.head input{
    width: 70px;
    background-color: black;
    border: none;
}
.head{
    font-size: 20px;
    font-family: "Proxima Nova Rg";
    cursor: pointer;
}
.head:hover{
    color: #DF7B9A;
}
.page{
    max-width: 1100px;
    margin: 0 auto;
    
}

.search{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.search button{
    background-color: rgb(59, 72, 102);
    color: white;
    height: 55px;
    width: 60px;
    font-size: 25px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.search input{
    width: 30rem;
    height: 50px;
    padding-left: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    font-size: 18px;
}
.blockFind{
    max-width: 1100px;
    margin: 15px auto;
    text-align: left;
    font-size: 24px;
    font-family: "Proxima Nova Lt";
    color: #3A4663;
    padding-bottom: 30px;
    border-bottom: #e4e4e4 solid 1px;
}
.resBtn{
    padding: 5px;
    background-color: white ;
    color: rgb(59, 72, 102);
    width: 150px;
    height: 45px;
    border-radius: 15px;
    display: flex;
    justify-items: center;
    align-items: center;
    box-shadow: 0 4px 11px rgb(0 0 0 / 20%);
    font-size: 20px;
    margin-top: 20px;
}
.resBtn:hover{
    background-color: rgb(59, 72, 102);
    color: white;
}